<template>
  <v-dialog v-model="dialog" width="500">
    <v-card color="background">
      <v-card-title>
        <div class="d-flex align-center">
          <div class="font-weight-bold">転送先のサーバーを選択</div>
          <v-spacer />
          <v-btn size="large" icon="mdi-close" variant="text" @click="dialog = false" />
        </div>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="searchText"
          prepend-inner-icon="mdi-magnify"
          placeholder="サーバーを検索"
          variant="solo"
          density="compact"
          hide-details
        />
        <v-divider class="my-4 mx-n6" color="black" thickness="2" />
        <div v-if="guildFrom" class="c-boost-move-dialog__guild-list">
          <div v-for="guild in filterdMovableGuilds" :key="guild.id" class="d-flex align-center mb-2">
            <VcGuildHeadline :guild="guild" size="small" />
            <v-spacer />
            <v-btn color="discord-primary" @click="move(guild.id)">転送</v-btn>
          </div>
        </div>
        <div v-if="!filterdMovableGuilds.length" class="mb-2">
          {{ noGuildText }}
        </div>
      </v-card-text>
      <v-overlay :model-value="isLoading" class="align-center justify-center" persistent contained>
        <v-progress-circular color="discord-primary" indeterminate size="64" />
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import type { Guild, BoostInfo } from "@/types";
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useAPI } from "@/composable/useAPI";
import { useGuildStore } from "@/stores/useGuildStore";
import { useBoostStore } from "@/stores/useBoostStore";
import { useSnackbarStore } from "@/stores/useSnackbarStore";

const props = defineProps<{
  userBoostedGuilds: BoostInfo[] | null;
}>();

const emit = defineEmits<{
  (e: "moved", closeDialog: () => void): void;
}>();

const guildStore = useGuildStore();
const boostStore = useBoostStore();
const snackbarStore = useSnackbarStore();

const { moveTargetBoost, guildFrom } = storeToRefs(boostStore);

const isLoading = ref(false);
const closeDialog = () => {
  isLoading.value = false;
  boostStore.setMoveTarget();
};

const dialog = computed({
  get() {
    return !!moveTargetBoost.value;
  },
  set(newValue: boolean) {
    if (!newValue) {
      closeDialog();
    }
  },
});

const searchText = ref("");

const filterdUserBoostedGuilds = computed<Guild[]>(() => {
  if (!props.userBoostedGuilds) return [];

  return props.userBoostedGuilds
    .map((boostInfo) => boostInfo.guild)
    .filter((guild) => guild.id !== guildFrom.value?.id);
});

const userBoostedGuildIds = computed<string[]>(() => {
  return props.userBoostedGuilds?.map((boostInfo) => boostInfo.guild.id) || [];
});

const notBoostedGuilds = computed(() => {
  return [...guildStore.managedJoinedGuilds, ...guildStore.notManagedJoinedGuilds].filter((guild) => {
    return !userBoostedGuildIds.value.includes(guild.id);
  });
});

const movableGuilds = computed(() => [...filterdUserBoostedGuilds.value, ...notBoostedGuilds.value]);
const filterdMovableGuilds = computed(() =>
  [...filterdUserBoostedGuilds.value, ...notBoostedGuilds.value].filter((guild) =>
    guild.name.includes(searchText.value),
  ),
);

const noGuildText = computed(() => {
  return movableGuilds.value.length && !filterdMovableGuilds.value.length
    ? "その名前のサーバーは見つかりませんでした"
    : "転送可能なギルドがありません";
});

const move = async (guildId: string) => {
  isLoading.value = true;
  useAPI()
    .post(`/api/boosts/${moveTargetBoost.value?.id}/move`, {
      destination_guild_id: guildId,
    })
    .then(() => {
      emit("moved", closeDialog);
    })
    .catch((error) => {
      snackbarStore.addError(error);
      closeDialog();
    });
};
</script>

<style lang="scss">
.c-boost-move-dialog__guild-list {
  overflow-y: auto;
  max-height: 500px;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
