<template>
  <div class="ma-4">
    <div class="text-h3 text-center mb-10">よくあるお問い合わせ</div>
    <template v-for="(group, groupIndex) in questionGroup" :key="groupIndex">
      <div class="text-h4 mt-8">{{ group.kind }}</div>
      <v-expansion-panels>
        <v-expansion-panel v-for="(question, questionIndex) in group.questions" :key="questionIndex">
          <v-expansion-panel-title>
            <div class="text-h6">{{ question.title }}</div>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="mx-n3">
            <div v-for="(row, rowIndex) in question.description.split('<br/>')" :key="rowIndex" class="ml-4">
              {{ row }}<br />
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
    <div class="text-h5 text-center mb-15 mt-15 pb-15 pt-15">
      よくある質問を見ても改善されない場合はサポートサーバーで質問することができます。
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

const questionGroup = ref([
  {
    kind: "一般",
    questions: [
      // {
      //   title: "VOISCORDの使い方を教えて下さい",
      //   description: "使い方ページをご覧下さい。",
      // },
      {
        title: "VOISCORD (NIGHTLY) の招待の仕方を教えて下さい。",
        description: "VOISCORDまたはNIGHTLYのWEBダッシュボードから未導入サーバーを選択し、招待ボタンを押してください。",
      },
      {
        title: "コマンドとは",
        description:
          "コマンドとはVOISCORDを操作するための命令のことです。<br/>\
          全てのコマンドは`/`から始まる命令文を入力することで実行できます。<br/>\
          コマンドの一覧はコマンド一覧ページをご覧下さい。<br/>\
          また、コマンドでできることはWEBからも操作できます。",
      },
      {
        title: "VOISCORDが喋りません",
        description:
          "【入退室アナウンスは読み上げる場合】<br/>\
          読み上げ対象のチャンネルが有効化されていない可能性があります。<br/>\
          `/vc link-global `や`/vc link`コマンドでチャンネルを有効化して下さい。<br/>\
          また、一般設定から「join実行チャンネルを読み上げる」機能をONにすると、<br/>\
          チャンネルが有効化されていなくてもjoinコマンドを入力したチャンネルを読み上げるようになります。<br/>\
          <br/>\
          【入退室アナウンスも読み上げない場合】<br/>\
          入退室アナウンス機能を有効化しているのにも関わらず読み上げない場合はVOISCORDの不具合の可能性があります。<br/>\
          その場合はサポートサーバーにてご報告をお願い致します。",
      },
      {
        title: "配信はして良いですか？",
        description:
          "どのような理由があろうと、VOISCORDが提供するデータの複製を固く禁じます。<br/>\
          詳しくは利用規約をご覧下さい。",
      },
      {
        title: "VOISCORDの設定はすべてのBOTで共有していますか？",
        description:
          "VOISCORD本体とサブBOTの設定は共通しています。<br/>\
          ただし、先行機能体験版のVOISCORD-NIGHTLYは設定を共有していません。",
      },
      {
        title: "追加BOTはどうやって操作するのですか？",
        description:
          "VOISCORD本体のjoin/leaveコマンドから操作できます。<br/>\
          自動入室設定を行っている場合は、空いているBOTが自動で入室します。<br/>\
          その他の機能はすべてVOISCORD本体と設定を共有しています。",
      },
      {
        title: "外国語は読めますか？",
        description: "流暢には読めませんが、英語のみ、カタカナ語程度には読むことができます。",
      },
      {
        title: "翻訳機能はありますか？",
        description: "翻訳機能はありません。",
      },
      {
        title: "読み上げ文字数をカウントする際の計算方法を教えてください。",
        description:
          "原則、読み上げ対象となったメッセージの文字数に準拠します。<br/>\
          <br/>\
          ただし、以下の点に注意してください。<br/>\
          ひらがな・カタカナ・漢字・数字・ローマ字以外：辞書変換していなければ、メッセージ内から削除されます。<br/>\
          たとえば、Ⅷ、α、조などはすべて削除され、読み上げ文字数としてはカウントされません。<br/>\
          ユーザーメンション・チャンネルメンション：ユーザー名やチャンネル名が読まれます。<br/>\
          カスタム絵文字・絵文字：削除されます。<br/>\
          URL：「URL」に置換されるため、3文字となります。<br/>\
          <br/>\
          ただし、上記の例外として、辞書登録がされている単語・文章については辞書設定が優先されます。",
      },
      {
        title: "入退室アナウンスは読み上げ文字数にカウントされますか？",
        description: "カウントされます。",
      },
    ],
  },
  {
    kind: "使い方",
    questions: [
      {
        title: "キャラクターを変更したい",
        description:
          "サーバー全体の標準キャラクターを変更する場合は`/config speaker`<br/>\
          ユーザー個別のキャラクターを変更する場合は`/speaker`で変更できます。",
      },
      {
        title: "自動入室しても読み上げない",
        description:
          "自動入室で読み上げさせるためにはWEBダッシュボードから読み上げ設定を行う必要があります。<br/>\
          WEBダッシュボードのチャンネル設定画面から各VCの読み上げ設定を行って下さい。",
      },
      {
        title: "/config auto-joinコマンドが使えなくなりました。",
        description:
          "/config auto-joinコマンドは廃止しました。<br/>\
          今後はWEBダッシュボードからの設定をお願い致します。",
      },
      {
        title: "/vc系コマンドが使えなくなりました。",
        description:
          "/vc系コマンドは廃止しました。<br/>\
          今後はWEBダッシュボードからの設定をお願い致します。",
      },
    ],
  },
  {
    kind: "課金システム",
    questions: [
      {
        title: "料金体系は？",
        description:
          "ブーストあたりの月額制です。詳しくはブースト特典一覧をご確認ください。<br/>\
          https://voiscord.net/plan",
      },
      {
        title: "領収書を発行することはできますか？",
        description:
          "簡易的な取引履歴であれば決済履歴ページをご参照ください。<br/>\
          正式な領収書が必要な場合はサポートサーバー、またはメールにてお問い合わせください。",
      },
      {
        title: "インボイス制度には対応していますか？",
        description: "インボイス制度には対応していません。",
      },
      {
        title: "適格請求書を発行することはできますか？",
        description: "適格請求書を発行することはできません。",
      },
      {
        title: "経費でブーストを購入することはできますか？",
        description: "貴社の経理担当にお問い合わせください。",
      },
      {
        title: "ブーストをするとどうなりますか？",
        description:
          "ブースト特典一覧をご覧ください。<br/>\
          https://voiscord.net/plan",
      },
      {
        title: "複数人で同じサーバーをブーストしたらどうなりますか？",
        description:
          "複数人で同じサーバーをブーストした場合、ブースト数は合算されます。<br/>\
          たとえば3人が同じサーバーに1つずつブーストした場合、そのサーバーのブースト数は3つとなります。",
      },
      {
        title: "間違ったサーバーにブーストしてしまいました。他のサーバーに転送できますか？",
        description:
          "ブーストは他のサーバーに何度でも転送することができます。<br/>\
          ブースト管理ページから対象となるブーストを選択して転送を行ってください。<br/>\
          また、転送したブーストは24時間後に再転送することができます。",
      },
      {
        title: "余った読み上げ枠は来月に繰り越されますか？",
        description: "読み上げ枠が繰り越されることはできません。毎月1日にリセットされます。",
      },
      {
        title: "BOT数が増えたらどうなりますか？",
        description: "BOTの数だけ同時に同じサーバー内の別々のボイスチャンネルでBOTを使用することができます。",
      },
      {
        title:
          "3ブーストしたらBOT数が3つ増えるということは、6つブーストをしたら使えるBOT数は6つ増えるということですか？",
        description:
          "いいえ。3ブースト目の特典として追加で3つサブBOTを解禁できますが、それ移行は増える事はありません。<br/>\
          ブースト数を問わずサブBOTは最大3機までです。",
      },
      {
        title: "安心してクレジットカード情報を登録できますか？",
        description:
          "私達は決済情報を保持しません。<br/>\
          決済情報はすべて決済代理業者が法令に基づき安全に保管しています。",
      },
      {
        title: "クレジットカード以外に支払方法はありますか？",
        description:
          "現在はクレジットカード、デビットカードにのみ対応しています。<br/>\
          <br/>\
          ただし、バーチャルカードを用いることでコンビニ等で入金したのち、そのカードを使ってブーストを行うことができます。<br/>\
          詳しくはKyashホームページをご確認ください。<br/>\
          https://www.kyash.co/",
      },
    ],
  },
  {
    kind: "エラー",
    questions: [
      {
        title: "チャンネルが見つかりません",
        description:
          "VOISCORDは`/join`コマンドを実行したユーザーが入っているボイスチャンネルを探して入室します。<br/>\
          そのためユーザーはコマンドを実行する前にボイスチャンネルに入室している必要があります。",
      },
      {
        title: "このコマンドを使うにはVOISCORDに以下の権限が必要です",
        description:
          "入ろうとしているボイスチャンネルに適切な権限が与えられていません。<br/>\
          everyoneに「チャンネルを見る」「接続」「発言」の権限が付いていない場合によく見られるエラーです。<br/>\
          諸事情によりeveryoneに同権限が付けられない場合は、ボイスチャンネルの権限にVOISCORDロールを追加し、同権限を付与して下さい。",
      },
    ],
  },
]);
</script>

<style>
.faq-card {
  margin-bottom: 20px;
  list-style: none;
  background-color: #2c2c2c;
  border-radius: 8px;
  overflow: hidden;
  padding: 8px;
}

.faq-card-header {
  display: flex;
  user-select: none;
  justify-content: space-between;
  padding: 10px;
  background-color: #1b1b1b;
  border-radius: 8px;
}

.faq-card-header h3 {
  margin: 0;
  color: #ffffffff;
  border-radius: 8px;
}

.card-content__description {
  padding: 10px;
  transition: max-height 0.9s ease-in-out;
  max-height: 0;
}

.card-content__description[data-open="true"] {
  max-height: 500px;
}

.faq-arrow-icon {
  display: inline-block;
  font-size: 16px;
  transition: transform 0.3s;
  color: #949494;
}

.arrow-rotate {
  transform: rotate(-90deg);
}
</style>
