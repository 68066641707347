<template>
  <div class="p-boost__container mt-4 pa-2 d-flex flex-column align-center">
    <div class="w-100 mb-8">
      <VcUserPaymentMethodList />
    </div>
    <VcGuildBoostCard
      v-for="{ guild: userBoostedGuild, boosts, currentSpeechTextCount, maxSpeechTextCount } in userBoostedGuilds"
      :key="userBoostedGuild.id"
      class="mb-8"
      :guild="userBoostedGuild"
      :boosts="boosts"
      :current-speech-text-count="currentSpeechTextCount"
      :max-speech-text-count="maxSpeechTextCount"
      @update="onUpdateBoosters"
    />

    <template v-if="hotUserGuilds && hotUserGuilds.length > 0">
      <v-divider v-if="userBoostedGuilds && userBoostedGuilds.length > 0" class="w-100 mt-2 mb-8" />
      <div class="w-100 text-h5 font-weight-bold text-left mb-8">
        <div v-if="mobile" class="text-center">このサーバーにはブーストが必要です</div>
        <div v-else>このサーバーにはブーストが必要です</div>
      </div>
      <VcGuildBoostCard
        v-for="{ guild: hotUserGuld, boosts, currentSpeechTextCount, maxSpeechTextCount } in hotUserGuilds"
        :key="hotUserGuld.id"
        class="mb-8"
        :guild="hotUserGuld"
        :boosts="boosts"
        :current-speech-text-count="currentSpeechTextCount"
        :max-speech-text-count="maxSpeechTextCount"
        @update="onUpdateBoosters"
      />
      <VcPlans />
    </template>

    <VcBoostMoveDialog :user-boosted-guilds="userBoostedGuilds" @moved="onMoved" />
    <VcBoostCancelDialog @canceled="onCanceled" />
  </div>
</template>

<script setup lang="ts">
import type { BoostInfo } from "@/types";
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";
import { useAPI } from "@/composable/useAPI";
import { useSnackbarStore } from "@/stores/useSnackbarStore";
import { useGuildStore } from "@/stores/useGuildStore";
import { useMenuStore } from "@/stores/useMenuStore";
import { useVGuildStore } from "@/stores/useVGuildStore";

const userBoostedGuilds = ref<BoostInfo[] | null>(null);
const hotUserGuilds = ref<BoostInfo[] | null>(null);
const snackbarStore = useSnackbarStore();
const guildStore = useGuildStore();
const vGuildStore = useVGuildStore();
const menuStore = useMenuStore();
const { mobile } = useDisplay();

const { guild, guilds } = storeToRefs(guildStore);
const { vGuild } = storeToRefs(vGuildStore);

const init = async () => {
  const res = await useAPI().get("/api/boosts");
  userBoostedGuilds.value = res.data.user_boosted_guilds;
  hotUserGuilds.value = res.data.hot_user_guilds;
};

const onUpdateBoosters = () => {
  init();
  snackbarStore.addTopRight({
    color: "success",
    text: "ブーストしました！",
  });
  menuStore.setMenu(guild.value, vGuild.value);
};

const onMoved = (closeDialog: () => void) => {
  init();
  snackbarStore.addTopRight({
    color: "success",
    text: "ブーストを転送しました！",
  });
  closeDialog();
};

const onCanceled = (closeDialog: () => void) => {
  init();
  snackbarStore.addTopRight({
    color: "success",
    text: "ブーストの購読をキャンセルしました！",
  });
  closeDialog();
};

init();

watch(guilds, () => {
  init();
});
</script>

<style lang="scss">
.p-boost__container {
  width: 1000px;
  max-width: 1000px;
}
</style>
