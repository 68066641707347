<template>
  <VcPlans />
</template>

<script setup lang="ts">
import { watch } from "vue";
import { storeToRefs } from "pinia";
import { useGuildStore } from "@/stores/useGuildStore";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useLoadingStore } from "@/stores/useLoadingStore";

const vGuildStore = useVGuildStore();
const guildStore = useGuildStore();
const loadingStore = useLoadingStore();

const { guild } = storeToRefs(guildStore);
const { vGuild } = storeToRefs(vGuildStore);

watch(vGuild, () => loadingStore.setIsGuildChangeLoading(false));
watch(guild, () => {
  if (vGuild.value.guild_id === "0") loadingStore.setIsGuildChangeLoading(false);
});
</script>
