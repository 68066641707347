<template>
  <div style="display: flex; justify-content: center; font-size: 18px; margin: 20px 0px">
    <div style="text-align: center">サポートサーバーに参加しますか？</div>
  </div>
  <div class="l-dashboard__v-dialog__support--button__container" style="font-size: 18px">
    <v-btn
      width="120px"
      color="nomal-button-color"
      text="やめておく"
      style="margin: 20px 5px; font-size: 12px"
      @click="emit('update:modelValue', false)"
    />
    <v-btn
      width="120px"
      color="discord-primary"
      target="_blank"
      text="参加する"
      href="https://discord.gg/HTJ8NTvf2V"
      style="margin: 20px 5px; font-size: 12px"
    />
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: "update:modelValue", newValue: boolean): void;
}>();
</script>
