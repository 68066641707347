import type { PaymentMethod } from "@/types";
import { defineStore } from "pinia";
import { useAPI } from "@/composable/useAPI";

export const usePaymentMethodStore = defineStore("payment_method", {
  state: () => {
    return {
      paymentMethods: [] as PaymentMethod[],
    };
  },

  actions: {
    async fetch(userId: string) {
      useAPI()
        .get(`/api/v_users/${userId}/payment_methods`)
        .then((response) => (this.paymentMethods = response.data));
    },
  },
});
