<template>
  <div class="vc-payments d-flex flex-column justify-space-between align-center">
    <v-table>
      <thead>
        <tr>
          <th>ステータス</th>
          <th>日付</th>
          <th>金額</th>
          <th>支払い方法</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(formattedPayment, index) in formattedPayments" :key="index">
          <td :class="`text-${formattedPayment.statusColor}`">{{ formattedPayment.status }}</td>
          <td>{{ formattedPayment.date }}</td>
          <td>¥ {{ formattedPayment.totalAmount.toLocaleString() }}</td>
          <td><VcCard :payment-method="formattedPayment.paymentMethod" :show-default="false" /></td>
        </tr>
      </tbody>
    </v-table>
    <v-spacer />
    <v-pagination v-model="pageWrap" :length="totalPage" class="w-100"></v-pagination>
  </div>
</template>

<script setup lang="ts">
import type { Payment } from "types";
import { ref, onMounted, computed, watch } from "vue";
import { storeToRefs } from "pinia";
import { useAPI } from "@/composable/useAPI";
import { useUserStore } from "@/stores/useUserStore";
import { formatDate } from "@/utilities/voiscord";

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const payments = ref<Payment[]>([]);
const page = ref(1);
const totalPage = ref(1);

const formattedPayments = computed(() => {
  return payments.value
    .filter((payment) => ["CAPTURED", "CANCELED"].includes(payment.status))
    .map((payment) => ({
      status: payment.status === "CAPTURED" ? "決済済み" : "決済を処理できませんでした",
      statusColor: payment.status === "CAPTURED" ? "success" : "danger",
      totalAmount: payment.total_amount,
      date: formatDate(payment.process_date),
      paymentMethod: {
        id: 0,
        card_no: payment.card_no,
        brand: payment.brand,
        card_id: "0",
        default_flag: false,
      },
    }));
});

const fetch = async () => {
  if (!user.value) return;

  await useAPI()
    .get(`/api/v_users/${user.value.id}/payments?page=${page.value}`)
    .then(({ data }) => {
      payments.value = data.payments;
      totalPage.value = data.total_pages;
    });
};

const pageWrap = computed({
  get() {
    return page.value;
  },
  set(newValue: number) {
    page.value = newValue;
    fetch();
  },
});

onMounted(async () => {
  await fetch();
});

watch(user, async () => {
  await fetch();
});
</script>

<style lang="scss">
.vc-payments {
  height: 640px;
  min-height: 600px;
}
</style>
