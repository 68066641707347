<template>
  <v-navigation-drawer
    v-if="!mobile"
    :model-value="drawer"
    width="320"
    class="l-dashboard__guilds"
    disable-route-watcher
    @update:model-value="userStore.toggleDrawer()"
  >
    <div class="d-flex h-100 pa-2 pl-0">
      <DashboardLayoutDrawerGuilds />
      <v-list class="l-dashboard__menus flex-grow-1" bg-color="surface-lighten-1">
        <template v-if="guild">
          <div class="l-dashboard__guild-name">
            {{ guild?.name || "・・・" }}
          </div>
          <v-divider class="my-2" color="black" thickness="2" />
        </template>
        <v-list-item
          v-for="menu in menus"
          :key="menu.title"
          :title="menu.title"
          :base-color="menu.color"
          :prepend-icon="menu.icon"
          rounded
          :active="menu.to === $route.path"
          @click="onClick(menu.to)"
        />
        <template v-if="vGuildStore.isSubscribed">
          <v-divider class="my-2" color="black" thickness="2" />
          <div class="mb-2">ブーストメンバー({{ vGuild.boosters.length }})</div>
          <v-list-item
            v-for="[boostCount, boosters] in boosterByCount"
            :key="boostCount"
            class="px-0"
            density="compact"
          >
            <v-chip class="pr-4 mb-1" density="compact">
              <template v-if="Number(boostCount) <= 5">
                <v-icon v-for="i in Number(boostCount)" :key="i" class="mr-n1" color="boost" size="small"
                  >mdi-lightning-bolt</v-icon
                >
              </template>
              <template v-else>
                <v-icon color="boost" size="small">mdi-lightning-bolt</v-icon> x {{ Number(boostCount) }}
              </template>
            </v-chip>
            <div v-for="booster in boosters" :key="booster.id" class="d-flex ml-2">
              <v-avatar color="black" size="32">
                <v-img :src="userIcon(booster)" width="32" />
              </v-avatar>
              <div class="mx-1 text-caption">
                <div class="l-dashboard__booster-name">{{ booster.nick || booster.name }}</div>
                <div class="l-dashboard__booster-name text-grey">{{ booster.username }}</div>
              </div>
            </div>
          </v-list-item>
        </template>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import type { User } from "@/types";
import { computed, watch, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { useDisplay } from "vuetify";
import { useUserStore } from "@/stores/useUserStore";
import { useGuildStore } from "@/stores/useGuildStore";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useMenuStore } from "@/stores/useMenuStore";
import { useLoadingStore } from "@/stores/useLoadingStore";
import { userIcon } from "@/utilities/discord";

const { mobile } = useDisplay();
const router = useRouter();
const route = useRoute();

const userStore = useUserStore();
const guildStore = useGuildStore();
const vGuildStore = useVGuildStore();
const menuStore = useMenuStore();
const loadingStore = useLoadingStore();

const { drawer } = storeToRefs(userStore);
const { guild } = storeToRefs(guildStore);
const { vGuild } = storeToRefs(vGuildStore);
const { menus } = storeToRefs(menuStore);

const boosterByCount = computed(() => {
  const boosterByCount: Record<string, User[]> = {};
  vGuild.value.boosters.forEach((booster) => {
    if (!booster.boostCount) return;

    boosterByCount[booster.boostCount.toString()] ||= [];
    boosterByCount[booster.boostCount.toString()].push(booster);
  });
  return Object.entries(boosterByCount).sort((x, y) => {
    return Number(y[0]) - Number(x[0]);
  });
});

const setDrawer = () => {
  userStore.setDrawer(!mobile.value);
};
watch(route, setDrawer);
watch(mobile, setDrawer);
setDrawer();

const onClick = (to: string) => {
  if (to === route.path) return;

  const notLoadingPathes = ["/payments", "/boost", "/dashboard", "bots"];
  if (notLoadingPathes.every((path) => !to.includes(path))) {
    loadingStore.setIsGuildChangeLoading(true);
  }

  nextTick(() => {
    // vGuildStore.setVGuild();
    router.push(to);
  });
};
</script>

<style lang="scss">
.l-dashboard__menus.v-navigation-drawer {
  .v-list {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.l-dashboard__guild-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  font-weight: bold;
  padding: 0 8px 8px;
}

.l-dashboard__menus {
  padding: 20px 6px;
  width: 240px;
}

.l-dashboard__booster-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 163px;
}
</style>
