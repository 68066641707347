import { defineStore } from "pinia";

const UNEXPECTED_ERROR =
  "予期せぬエラーが発生しました。時間が経っても解決しない場合はVOISCORD運営にお問い合わせ下さい。";

interface Snackbar {
  id: number;
  color: string;
  text: string;
  location?: "top right";
  locationStrategy?: "connected";
  timeout?: number;
  contentClass?: string;
}

export const useSnackbarStore = defineStore("snackbar", {
  state: () => {
    return {
      snackbars: [] as Snackbar[],
      snackbarId: 0,
    };
  },

  actions: {
    addTopRight(snackbar: Pick<Snackbar, "color" | "text" | "timeout">) {
      this.snackbars.push({
        timeout: 1000,
        id: this.snackbarId++,
        locationStrategy: "connected",
        ...snackbar,
        location: "top right",
      });
    },
    addError(data?: { error?: string }) {
      let text = data?.error || UNEXPECTED_ERROR;
      text = text === "500 InternalServerError" ? UNEXPECTED_ERROR : text;
      this.addTopRight({ color: "danger", timeout: -1, text });
    },
    addDefaultGetError(data?: { error?: string }) {
      if (data?.error && data.error !== "500 InternalServerError") {
        this.addTopRight({
          color: "danger",
          text: data.error,
          timeout: -1,
        });
      } else {
        this.addTopRight({
          color: "danger",
          text: "データの取得に失敗しました。時間をおいて再度試しても改善しない場合はお問合せください。",
          timeout: -1,
        });
      }
    },
    addDefaultUpdateError() {
      this.addTopRight({
        color: "danger",
        text: "データの更新に失敗しました。時間をおいて再度試しても改善しない場合はお問合せください。",
        timeout: -1,
      });
    },
    remove(id: number) {
      const index = this.snackbars.findIndex((s) => s.id === id);
      this.snackbars.splice(index, 1);
    },
  },
});
