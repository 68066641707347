<template>
  <v-app>
    <v-app-bar class="l-lp__app-bar" elevation="3">
      <router-link to="/" class="d-flex text-h5 align-center">
        <v-img class="ml-2" :src="yukariUrl" height="40" width="40" />
        VOISCORD
      </router-link>
      <v-spacer />
      <template v-if="!mobile">
        <v-btn class="bg-grey-darken-3 mr-2" @click="onClickPlanButton">ブースト特典一覧</v-btn>
        <v-btn class="bg-grey-darken-3 mr-2" to="/commands">コマンド</v-btn>
        <v-btn class="bg-grey-darken-3 mr-2" to="/questions">FAQ</v-btn>
        <v-btn
          class="bg-grey-darken-3 mr-2"
          href="https://discord.gg/HTJ8NTvf2V"
          target="_blank"
          rel="noreferrer noopener"
          >お問い合わせ</v-btn
        >
      </template>
      <v-menu v-if="user">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            class="l-lp__user-info pl-2 pr-1"
            elevation="3"
            :style="{ backgroundColor: user.accent_color }"
          >
            <v-avatar color="primary" size="32">
              <v-img :src="userIcon(user)" width="32" />
            </v-avatar>
            <div v-if="!mobile" class="l-lp__user-info-name">
              {{ user.name }}
            </div>
            <v-icon icon="mdi-menu-down" />
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/dashboard">ダッシュボード</v-list-item>
          <v-list-item to="/commands">コマンド</v-list-item>
          <v-list-item to="/questions">FAQ</v-list-item>
          <v-list-item to="/plan">ブースト特典一覧</v-list-item>
          <v-list-item @click="onClickShowServerJoin"> お問い合わせ </v-list-item>
          <v-list-item key="logout" tag="button" type="submit" class="w-100 text-left" @click="logoutFormRef?.submit()">
            <v-list-item-title class="text-danger">ログアウト</v-list-item-title>
          </v-list-item>
        </v-list>
        <form ref="logoutFormRef" class="d-none" method="post" action="/logout">
          <input type="hidden" name="_method" value="delete" />
        </form>
      </v-menu>
      <v-btn v-else class="bg-discord-primary" href="/login">ログイン</v-btn>
    </v-app-bar>
    <v-main>
      <div id="top"></div>
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </v-main>
    <v-footer>
      <div class="d-flex w-100" :class="{ 'flex-wrap': mobile }">
        <div class="flex-grow-1">
          <div class="d-inline-flex align-center text-h5 mt-4">
            <v-img class="ml-2" :src="yukariUrl" height="32" width="32" />
            VOISCORD
          </div>
          <div class="pa-4">
            さあ、新しい声を手に入れよう。<br />
            A.I.VOICE※によるもう一つの「声」<br />
            コミュニティーを盛り上げて<br />
            ボイスチャットを楽しくする<br />
            今までにない読み上げBOT<br />
          </div>
        </div>
        <div class="d-flex">
          <v-list>
            <v-list-item class="text-h6"><b>リンク一覧</b></v-list-item>
            <v-list-item class="text-grey" density="compact" to="/">TOP</v-list-item>
            <v-list-item
              class="text-grey"
              density="compact"
              :to="user ? '/dashboard' : undefined"
              :href="user ? undefined : '/login'"
              >ダッシュボード</v-list-item
            >
            <v-list-item class="text-grey" density="compact" to="/plan">ブースト特典一覧</v-list-item>
            <v-list-item class="text-grey" density="compact" to="/commands">コマンド</v-list-item>
            <v-list-item class="text-grey" density="compact" to="/questions">FAQ</v-list-item>
            <v-list-item
              class="text-grey"
              density="compact"
              href="https://discord.gg/HTJ8NTvf2V"
              target="_blank"
              rel="noreferrer noopener"
              >お問い合わせ</v-list-item
            >
          </v-list>
          <v-list>
            <v-list-item class="text-h6"><b>規約とポリシー</b></v-list-item>
            <v-list-item class="text-grey" density="compact" to="/tos">利用規約</v-list-item>
            <v-list-item class="text-grey" density="compact" to="/privacy">プライバシーポリシー</v-list-item>
            <v-list-item class="text-grey" density="compact" to="/tokusho">特定商取引法に基づく表記</v-list-item>
          </v-list>
        </div>
      </div>
    </v-footer>

    <v-dialog v-model="showServerJoin" class="l-dashboard__v-dialog" :class="{ 'is-mobile': mobile }" eager>
      <v-card style="margin: auto; padding: 0px 20px" max-width="400px">
        <v-card-text class="l-dashboard__v-dialog-card" :class="{ 'pa-1': mobile }">
          <VcSupportServer @update:model-value="showServerJoin = false" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useDisplay } from "vuetify";
import { storeToRefs } from "pinia";
import VueScrollTo from "vue-scrollto";
import yukariUrl from "@/assets/images/yukari.png";
import { useUserStore } from "@/stores/useUserStore";
import { userIcon } from "@/utilities/discord";

const { mobile } = useDisplay();

const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const showServerJoin = ref(false);

const onClickShowServerJoin = () => {
  showServerJoin.value = true;
};

if (!user.value) {
  userStore.fetchUser(false);
}

const logoutFormRef = ref<HTMLFormElement>();

const onClickPlanButton = () => {
  VueScrollTo.scrollTo("#plan", 200, {
    easing: "ease-in",
    lazy: false,
    force: true,
    cancelable: false,
  });
};
</script>

<style lang="scss" scoped>
.l-lp__user-info {
  height: 48px;
}

.l-lp__user-info-name {
  text-transform: none;
  min-width: 120px;
  text-align: left;
  margin-left: 8px;
}
</style>
