<template>
  <v-card class="c-user-payment-list">
    <v-divider color="black" />
    <div class="user-payment-method-list__user">
      <v-avatar color="primary" size="62">
        <v-img :src="userIcon(user)" width="62" />
      </v-avatar>
      <div class="ml-4">
        <div class="font-weight-bold mb-1" style="font-size: 20px">支払方法</div>
        <div>
          <VcCardDialog :user-id="user?.id">
            <template #default="{ props: cardDialogProps }">
              <v-btn v-bind="cardDialogProps" color="nomal-button-color">新しい支払方法を登録する</v-btn>
            </template>
          </VcCardDialog>
          <br />
          <v-btn v-if="mobile" class="mt-2" color="nomal-button-color" @click="showPayments = true">決済履歴</v-btn>
        </div>
      </div>
    </div>
    <div v-if="paymentMethods && paymentMethods.length > 0">
      <div v-for="(paymentMethod, index) in paymentMethods" :key="index">
        <div class="c-user-payment-list__method bg-black d-flex align-center">
          <VcCard :payment-method="paymentMethod" show-default />
          <v-spacer />
          <v-menu>
            <template #activator="{ props: activatorProps }">
              <v-btn
                v-bind="activatorProps"
                icon="mdi-dots-vertical"
                size="small"
                vairant="text"
                class="bg-transparent"
              />
            </template>
            <v-list>
              <v-list-item @click="remove(paymentMethod)">
                <v-list-item-title class="text-danger-lighten">削除</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="c-user-payment-list__method bg-black d-flex align-center">
        <v-icon class="mr-2" color="#78909C" size="small">mdi-credit-card-check-outline</v-icon>
        支払方法が登録されていません
        <v-spacer />
      </div>
    </div>
  </v-card>
  <v-dialog
    v-model="showPayments"
    class="vc-user-payment-method-list__show-payment-dialog"
    :class="{ 'is-mobile': mobile }"
    :fullscreen="mobile"
    eager
    min-height="calc(100vh - 128px)"
  >
    <v-card>
      <v-toolbar theme="dark">
        <v-btn icon @click="showPayments = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>決済履歴</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="l-dashboard__card-text" :class="{ 'pa-0': mobile }">
        <VcPayments />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import type { PaymentMethod } from "types";
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from "vue";
import { useDisplay } from "vuetify";
import { useAPI } from "@/composable/useAPI";
import { useUserStore } from "@/stores/useUserStore";
import { usePaymentMethodStore } from "@/stores/usePaymentMethodStore";
import { useSnackbarStore } from "@/stores/useSnackbarStore";
import { userIcon } from "@/utilities/discord";
import VcCardDialog from "./VcCardDialog.vue";

const { mobile } = useDisplay();

const snackbarStore = useSnackbarStore();
const userStore = useUserStore();
const paymentMethodStore = usePaymentMethodStore();
const { user } = storeToRefs(userStore);
const { paymentMethods } = storeToRefs(paymentMethodStore);
const showPayments = ref(false);

onMounted(async () => {
  if (user?.value?.id) {
    await paymentMethodStore.fetch(user.value.id);
  }
});

watch(user, async (newUser) => {
  if (newUser) {
    if (user?.value?.id) {
      await paymentMethodStore.fetch(user.value.id);
    }
  }
});

const remove = async (paymentMethod: PaymentMethod) => {
  await useAPI()
    .delete(`/api/v_users/${user?.value?.id}/payment_methods/${paymentMethod.id}`)
    .then(async () => {
      snackbarStore.addTopRight({ color: "success", text: "支払方法を削除しました" });
    })
    .catch(async ({ response: { data } }) => {
      snackbarStore.addError(data);
    })
    .finally(async () => {
      if (user?.value?.id) {
        await paymentMethodStore.fetch(user.value.id);
      }
    });
};
</script>

<style lang="scss">
.user-payment-method-list__user {
  display: flex;
  align-content: flex-start;
  margin-bottom: 8px;
  padding: 16px;
}

.c-user-payment-list {
  min-width: 500px;
  padding-top: 16px;

  .is-mobile & {
    min-width: 0;
    width: 100%;
  }
}

.c-user-payment-list__method {
  padding: 0 8px;
  height: 40px;
  min-height: 40px;
}

.new-cpayment-method-button {
  color: #ffffffff;
  background-color: #ffffff50;
}

.new-cpayment-method-button:hover {
  background-color: #ffffff8f;
}
</style>
