<template>
  <v-dialog v-model="dialog" width="500">
    <v-card color="background">
      <v-card-title>
        <div class="d-flex align-center">
          <div class="font-weight-bold">このブーストのサブスクリプションを解約する</div>
          <v-spacer />
          <v-btn size="large" icon="mdi-close" variant="text" @click="dialog = false" />
        </div>
      </v-card-title>
      <v-card-text>
        <div>解約後も{{ formatDate(cancelTargetBoost?.next_payment_at || "") }}までこのブーストは有効です。</div>
        <div>解約すると、ただちにサブスクリプションが削除され、解約の取り消しはできません。</div>
        <div class="mt-6">このブーストのサブスクリプションを解約しますか？</div>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer />
        <v-btn variant="text" @click="closeDialog">キャンセル</v-btn>
        <v-btn variant="elevated" color="danger" @click="cancel">解約する</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useAPI } from "@/composable/useAPI";
import { useBoostStore } from "@/stores/useBoostStore";
import { useSnackbarStore } from "@/stores/useSnackbarStore";
import { formatDate } from "@/utilities/voiscord";

const emit = defineEmits<{
  (e: "canceled", closeDialog: () => void): void;
}>();

const boostStore = useBoostStore();
const snackbarStore = useSnackbarStore();

const { cancelTargetBoost } = storeToRefs(boostStore);

const isLoading = ref(false);
const closeDialog = () => {
  isLoading.value = false;
  boostStore.setCancelTarget();
};

const dialog = computed({
  get() {
    return !!cancelTargetBoost.value;
  },
  set(newValue: boolean) {
    if (!newValue) {
      closeDialog();
    }
  },
});

const cancel = () => {
  isLoading.value = true;
  useAPI()
    .post(`/api/boosts/${cancelTargetBoost.value?.id}/cancel`)
    .then(() => {
      emit("canceled", closeDialog);
    })
    .catch((error) => {
      snackbarStore.addError(error);
      closeDialog();
    });
};
</script>
