<template>
  <v-card class="vc-setting" flat>
    <VcSettingAutoSaveChip />
    <v-card-text>
      <div class="d-block d-sm-none">
        <div class="d-flex align-center mb-2">
          <v-icon v-if="onlySubscribed" class="mr-1" icon="mdi-lightning-bolt" color="boost" />
          <v-label class="vc-setting__label mr-auto" :text="label" />
          <VcSettingNumberInput v-model="value" :min="min" :max="max" :disabled="disabled" />
        </div>
        <VcSettingNumberSlider
          v-model="value"
          :percent="percent"
          :initial-value="initialValue"
          :min="min"
          :max="max"
          :disabled="disabled"
        />
      </div>
      <div class="d-none d-sm-flex justify-space-between align-center">
        <v-icon v-if="onlySubscribed" class="mr-1" icon="mdi-lightning-bolt" color="boost" />
        <v-label class="vc-setting__label" :text="label" />
        <v-spacer />
        <VcSettingNumberSlider
          v-model="value"
          :percent="percent"
          :initial-value="initialValue"
          :min="min"
          :max="max"
          :disabled="disabled"
        />
        <VcSettingNumberInput v-model="value" :min="min" :max="max" :disabled="disabled" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useClamp } from "@/composable/useLodash";
import { useVGuildStore } from "@/stores/useVGuildStore";

const props = defineProps<{
  percent?: boolean;
  modelValue: number;
  label: string;
  initialValue: number;
  min: number;
  max: number;
  onlySubscribed?: boolean;
  requiredBoostCount?: number;
  disabled?: boolean;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", newValue: number): void;
}>();

const vGuildStore = useVGuildStore();
const disabled = computed(() => {
  return props.disabled || (props.requiredBoostCount && props.requiredBoostCount > vGuildStore.boostCount);
});

const value = computed({
  get(): number {
    return disabled.value ? props.initialValue : props.modelValue;
  },
  set(newValue: number) {
    emit("update:modelValue", useClamp(newValue, props.min, props.max));
  },
});
</script>
