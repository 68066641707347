<template>
  <v-list class="l-dashboard__guilds pb-10" color="transparent" active-class="is-active" width="80" rounded>
    <v-list-item class="l-dashboard__guild text-center" to="/dashboard" nav>
      <v-avatar color="primary" size="56">
        <v-img :src="userIcon(user)" width="56" />
      </v-avatar>
    </v-list-item>
    <div v-for="(userGuilds, index) in guildsByStatus" :key="index">
      <v-divider class="my-2 mx-5" color="grey" thickness="2" />
      <v-list-item
        v-for="userGuild in userGuilds"
        :key="userGuild.id"
        class="l-dashboard__guild text-center"
        :active="route.path.includes(`/server/${userGuild.id}`)"
        :ripple="false"
        nav
        @click="moveToServer(userGuild)"
      >
        <v-tooltip
          :text="userGuild.name"
          :close-delay="0"
          :open-delay="0"
          :offset="20"
          :height="40"
          content-class="l-dashboard__guild-tooltip"
        >
          <template #activator="{ props }">
            <GuildIcon v-bind="props" :guild="userGuild" :size="56" :active="userGuild.id === guild?.id" />
          </template>
        </v-tooltip>
      </v-list-item>
    </div>
  </v-list>
</template>

<script setup lang="ts">
import type { Guild } from "@/types";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { computed, nextTick } from "vue";
import { useUserStore } from "@/stores/useUserStore";
import { useGuildStore } from "@/stores/useGuildStore";
import { useLoadingStore } from "@/stores/useLoadingStore";
import { userIcon } from "@/utilities/discord";

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const guildStore = useGuildStore();
const loadingStore = useLoadingStore();

const { user } = storeToRefs(userStore);
const { guild, guilds } = storeToRefs(guildStore);

const guildsByStatus = computed(() => {
  if (!guilds.value) {
    return [];
  }

  return [guildStore.managedJoinedGuilds, guildStore.notManagedJoinedGuilds, guildStore.managedNotJoinedGuilds];
});

const moveToServer = (targetGuild: Guild) => {
  if (guild.value?.id === targetGuild.id) return;

  loadingStore.setIsGuildChangeLoading(true);
  nextTick(() => {
    const allowNames = ["general", "member", "channel", "dictionary", "moderator"];
    const matches = route.path.match(/^\/server\/\d+\/(.+)$/);
    const currentPageName = (matches && matches[1]) || "general";
    const pageName = allowNames.includes(currentPageName) ? currentPageName : "general";
    const query = route.query.navigate_to ? `?navigate_to=${route.query.navigate_to}` : "";
    const path = `/server/${targetGuild.id}/${pageName}${query}`;
    router.push(path);
  });
};
</script>

<style lang="scss">
@use "@/stylesheets/mixin.scss";

.l-dashboard__guilds.v-navigation-drawer .v-list {
  @include mixin.no-scrollbar;
}

.l-dashboard__guild.v-list-item:hover > .v-list-item__overlay {
  opacity: 0;
}

.l-dashboard__guild {
  position: relative;

  &::before {
    content: "";
    opacity: 0;
    position: absolute;
    left: -4px;
    background-color: #fff;
    border-radius: 4px;
    padding: 0;
    transition: all 0.3s ease;
  }

  &:hover::before {
    opacity: 1;
    padding: 12px 4px;
  }

  &.is-active::before {
    opacity: 1;
    padding: 24px 4px;
  }
}

.v-tooltip .v-overlay__content.l-dashboard__guild-tooltip {
  background-color: black;
  line-height: 32px;
  font-size: 14px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -7px;
    margin-top: -4px;
    border: 4px solid transparent;
    border-right: 4px solid black;
  }
}
</style>
