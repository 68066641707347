import type { Channel, VChannel } from "@/types";
import { defineStore } from "pinia";
import { useAPI } from "@/composable/useAPI";
import { useSnackbarStore } from "@/stores/useSnackbarStore";
import { useVGuildStore } from "./useVGuildStore";
import { useChannelStore } from "./useChannelStore";

const getGuildId = () => useVGuildStore().vGuild.guild_id;

export const useVChannelStore = defineStore("v_channels", {
  state: () => {
    return {
      vChannels: [] as VChannel[],
    };
  },

  actions: {
    async fetchVChannels() {
      const guildId = getGuildId();
      if (guildId === "0") return;

      const response = await useAPI()
        .get(`/api/v_guilds/${guildId}/v_channels`)
        .catch(() => useSnackbarStore().addDefaultGetError());
      if (!response) return;

      this.vChannels = response.data;
    },
    async updateAutoJoin(channelId: string, newValue: boolean) {
      const guildId = getGuildId();
      if (guildId === "0") return;

      const params = { auto_join: newValue };
      const response = await useAPI()
        .patch(`/api/v_guilds/${guildId}/v_channels/${channelId}`, params)
        .catch(() => useSnackbarStore().addDefaultUpdateError());
      if (!response) return;

      const vChannel = this.vChannels.find((vChannel) => vChannel.channel_id === channelId);
      if (vChannel) {
        Object.assign(vChannel, response.data);
      } else {
        this.vChannels.push(response.data);
      }
      useSnackbarStore().addTopRight({
        color: "success",
        text: "保存しました",
      });
    },
    async updateReadChannel(voiceChannel: Channel, textChannel: Channel, newValue: boolean | null) {
      const guildId = getGuildId();
      if (guildId === "0") return;

      const path = `/api/v_guilds/${guildId}/v_channels/${voiceChannel.id}/v_links/${textChannel.id}`;
      const vChannel = this.vChannels.find((vChannel) => vChannel.channel_id === voiceChannel.id);
      if (newValue === null) {
        const response = await useAPI()
          .delete(path)
          .catch(() => useSnackbarStore().addDefaultUpdateError());
        if (!response) return;

        if (vChannel) {
          vChannel.channel_ids = vChannel.channel_ids.filter((id) => id !== textChannel.id);
          vChannel.ignore_channel_ids = vChannel.ignore_channel_ids.filter((id) => id !== textChannel.id);
        }
        useSnackbarStore().addTopRight({
          color: "success",
          text: "保存しました",
        });
      } else {
        const response = await useAPI()
          .patch(path, { read: newValue })
          .catch(() => useSnackbarStore().addDefaultUpdateError());
        if (!response) return;

        if (vChannel) {
          if (newValue) {
            vChannel.channel_ids.push(textChannel.id);
            vChannel.ignore_channel_ids = vChannel.ignore_channel_ids.filter((id) => id !== textChannel.id);
          } else {
            vChannel.channel_ids = vChannel.channel_ids.filter((id) => id !== textChannel.id);
            vChannel.ignore_channel_ids.push(textChannel.id);
          }
        } else {
          const vChannel = {
            channel_id: voiceChannel.id,
            auto_join: false,
            channel_ids: newValue ? [textChannel.id] : [],
            ignore_channel_ids: newValue ? [] : [textChannel.id],
          };
          this.vChannels.push(vChannel);
          const channelStore = useChannelStore();
          if (channelStore.currentChannel) {
            channelStore.updateCurrentChannel({
              ...channelStore.currentChannel,
              vChannel,
            });
          }
        }
        useSnackbarStore().addTopRight({
          color: "success",
          text: "保存しました",
        });
      }
    },
  },
});
