<template>
  <v-avatar class="c-guild-icon__avatar text-center" :class="iconClass" nav color="black" :size="size">
    <v-img v-if="guild?.icon.length" class="c-guild-icon__img" :src="guild.icon" :width="size" />
    <span v-else class="c-guild-icon__text" :style="{ fontSize: fontSizePx }">
      {{ guild?.name }}
    </span>
  </v-avatar>
</template>

<script setup lang="ts">
import type { Guild } from "@/types";
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    guild?: Guild;
    size: number;
    active?: boolean;
    hoverable?: boolean;
  }>(),
  {
    guild: undefined,
    hoverable: true,
  },
);

const iconClass = computed(() => {
  return {
    [`is-${props.guild?.status}`]: true,
    "is-active": props.active,
    "is-hoverable": props.hoverable,
  };
});
const fontSizePx = computed(() => {
  const fontSize = Math.floor(props.size / 5.6);
  return `${fontSize}px`;
});
</script>

<style lang="scss">
.c-guild-icon__avatar {
  isolation: isolate;
  border: 2px solid white;
  border-radius: 50%;
  transition: border-radius 0.3s ease;

  &.is-managed_joined {
    border-width: 4px;
    border-color: rgb(var(--v-theme-discord-primary));
  }

  &.is-not_managed_joined {
    border-width: 4px;
    border-color: rgb(var(--v-theme-inactive));
  }

  &.is-managed_not_joined {
    border-width: 4px;
    border-color: #000;
  }

  &.is-hoverable:hover,
  &.is_active {
    border-radius: 35%;
  }
}

.c-guild-icon__img {
  .c-guild-icon__avatar.is-managed_not_joined & {
    filter: grayscale(100%);
  }
}

.c-guild-icon__text {
  white-space: initial;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  pointer-events: none;
}
</style>
