import type { VGuild } from "@/types";
import { defineStore } from "pinia";
import { useAPI } from "@/composable/useAPI";

const NoneVGuild: VGuild = {
  id: "0",
  guild_id: "0",
  plan: "basic",
  speaker: "",
  volume: 0,
  speaking_volume_percentage: 0,
  speed: 0,
  pitch: 0,
  max_message_len: 0,
  min_number_len_reading_as_digits: 0,
  speech_text_count: 0,
  read_bot_message: false,
  join_announce_format: "",
  leave_announce_format: "",
  call_by_nickname: false,
  user_customization: false,
  created_at: "",
  updated_at: "",
  exception_prefixies: [],
  stop_commands: [],
  channels: [],
  links: [],
  members: [],
  message_format: "",
  only_voice_members: false,
  include_command_channel: false,
  inherit_readability_to_thread: false,
  read_voice_channel_itself: false,
  read_name_skip_interval_type: "zero",
  boosters: [],
};

export const useVGuildStore = defineStore("v_guilds", {
  state: () => {
    return {
      vGuild: NoneVGuild,
    };
  },

  actions: {
    async fetchVGuild(guildId: string, withAuthorities = true) {
      let path: string;
      if (withAuthorities) {
        path = `/api/v_guilds/${guildId}?with_authorities=true`;
      } else {
        path = `/api/v_guilds/${guildId}`;
      }

      return await useAPI()
        .get(path)
        .then(async (response) => response.data as VGuild)
        .catch(() => (this.vGuild = NoneVGuild));
    },
    setVGuild(vGuild?: VGuild) {
      this.vGuild = vGuild || NoneVGuild;
    },
  },

  getters: {
    boostCount({ vGuild }) {
      return vGuild.boosters.reduce((sum, booster) => sum + (booster.boostCount || 0), 0);
    },
    isSubscribed({ vGuild }) {
      return vGuild.boosters.length > 0;
    },
  },
});
