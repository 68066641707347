<template>
  <div class="vc-plan w-100 mx-auto" :class="{ 'is-mobile': mobile }">
    <div v-if="showTitle" class="text-h4 text-center w-100 font-weight-bold mt-4 mb-6">
      <div class="d-inline-block">みんなでサーバーをブーストして</div>
      <div class="d-inline-block">特典をアンロックしよう！</div>
    </div>

    <slot />

    <div class="d-flex flex-wrap justify-space-between gap-6 pa-1">
      <v-card class="flex-grow-1" color="boost" prepend-icon="mdi-chat-plus" title="読み上げ文字数を追加">
        <v-divider color="boost-lighten" thickness="1" opacity="100" />
        <v-card-text>
          <div class="d-flex flex-wrap align-center">
            <div class="mr-4">1ブーストごとに</div>
            <div>
              <v-alert class="text-white" max-width="240" icon="mdi-plus" density="compact" variant="outlined">
                <v-alert-title> 毎月 100,000 文字 </v-alert-title>
              </v-alert>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <v-card class="flex-grow-1" color="boost" prepend-icon="mdi-gift-outline" title="1ブースト特典">
        <v-divider color="boost-lighten" thickness="1" opacity="100" />
        <v-card-text>
          <div class="mb-3">
            <v-icon class="mr-1" size="large">mdi-chat-plus</v-icon>最大メッセージ長増加: 140文字まで
          </div>
          <div class="mb-3"><v-icon class="mr-1" size="large" icon="mdi-chat-plus" /> スピード変更</div>
          <div class="mb-3"><v-icon class="mr-1" size="large" icon="mdi-chat-plus" /> ピッチ変更</div>
          <div class="mb-3"><v-icon class="mr-1" size="large" icon="mdi-chat-plus" /> BOTの読み上げ</div>
          <div><v-icon class="mr-1" size="large">mdi-furigana-horizontal</v-icon> 辞書スロット追加: 合計 300個</div>
        </v-card-text>
      </v-card>

      <v-card class="flex-grow-1" color="boost" prepend-icon="mdi-gift-outline" title="3ブースト特典">
        <v-divider color="boost-lighten" thickness="1" opacity="100" />
        <v-card-text>
          <div class="mb-3"><v-icon class="mr-1" size="large">mdi-volume-plus</v-icon> 読み上げBOT追加: 合計 4台</div>
        </v-card-text>
      </v-card>
    </div>

    <div id="details" class="text-h4 text-center w-100 font-weight-bold mt-12 mb-6">特典を比較する</div>

    <v-table class="p-plan__table bg-transparent pa-1 pb-10" density="compact">
      <thead>
        <tr>
          <th class="text-h5 text-white">
            <div class="pb-6"></div>
            特典
          </th>
          <th class="text-h5 font-weight-bold text-center">
            <div v-if="mobile" class="d-flex align-center justify-center text-grey">
              <v-icon class="d-inline-block mb-1" size="small" icon="mdi-lightning-bolt" /> × 0
            </div>
            <div v-else class="d-inline-block text-grey">ブーストなし</div>
            <div class="text-subtitle-1 text-blue-darken-3 font-weight-bold">無料</div>
          </th>
          <th class="text-h5 font-weight-bold text-center p-plan__osusume p-plan__osusume--top">
            <v-chip class="mb-3" color="boost">まずはお試し</v-chip><br />
            <div v-if="mobile" class="d-flex align-center justify-center">
              <v-icon class="d-inline-block mb-1" color="boost" size="small" icon="mdi-lightning-bolt" /> × 1
            </div>
            <template v-else>
              <div class="d-flex justify-center align-center">
                <v-icon class="d-inline-block" color="boost" icon="mdi-lightning-bolt" />
              </div>
              <div class="d-inline-block">1ブースト</div>
            </template>
            <div class="text-subtitle-1 text-blue-darken-3 font-weight-bold">¥200／月</div>
          </th>
          <th class="text-h5 font-weight-bold text-center">
            <div v-if="mobile" class="d-flex align-center justify-center">
              <v-icon class="d-inline-block mb-1" color="boost" size="small" icon="mdi-lightning-bolt" /> × 3
            </div>
            <template v-else>
              <div class="d-flex justify-center align-center">
                <v-icon class="d-inline-block" color="boost" icon="mdi-lightning-bolt" />
                <v-icon class="d-inline-block" color="boost" icon="mdi-lightning-bolt" />
                <v-icon class="d-inline-block" color="boost" icon="mdi-lightning-bolt" />
              </div>
              <div class="d-inline-block">3ブースト</div>
            </template>
            <div class="text-subtitle-1 text-blue-darken-3 font-weight-bold">¥600／月</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="label in labels" :key="label.key">
          <td>{{ label.name }}</td>
          <td
            v-for="(row, plan) of data"
            :key="plan"
            class="text-center"
            :class="{
              'p-plan__osusume': plan === 'standard',
              'p-plan__osusume--bottom': plan === 'standard' && label.key === 'botCount',
            }"
          >
            <template v-if="row[label.key].text">
              <div
                v-for="(line, index) in row[label.key].text?.split(`\n`) || []"
                :key="index"
                :class="{ [`text-${row[label.key].color}`]: row[label.key].color }"
              >
                <span :class="{ text: plan !== 'basic' && !(plan === 'standard' && label.key === 'botCount') }">{{
                  line
                }}</span>
                <br v-if="index !== (row[label.key].text?.split(`\n`)?.length || 0) - 1" />
              </div>
            </template>
            <v-icon v-else :color="row[label.key].color" size="x-large">{{ row[label.key].icon }}</v-icon>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script setup lang="ts">
import { useDisplay } from "vuetify";

withDefaults(
  defineProps<{
    showTitle?: boolean;
  }>(),
  {
    showTitle: true,
  },
);

const { mobile } = useDisplay();

const initial = { text: undefined, icon: undefined, color: undefined };
const data = {
  basic: {
    correctSpeechTextCounts: { ...initial, text: "5,000" },
    maxMessageLen: { ...initial, text: "60" },
    maxDictionaryCount: { ...initial, text: "50" },
    speed: { ...initial, icon: "mdi-minus", color: "inactive" },
    pitch: { ...initial, icon: "mdi-minus", color: "inactive" },
    readBotMessage: { ...initial, icon: "mdi-minus", color: "inactive" },
    botCount: { ...initial, text: "1" },
  },
  standard: {
    correctSpeechTextCounts: { ...initial, text: "100,000", color: "boost" },
    maxMessageLen: { ...initial, text: "140", color: "boost" },
    maxDictionaryCount: { ...initial, text: "300", color: "boost" },
    speed: { ...initial, icon: "mdi-check", color: "boost" },
    pitch: { ...initial, icon: "mdi-check", color: "boost" },
    readBotMessage: { ...initial, icon: "mdi-check", color: "boost" },
    botCount: { ...initial, text: "1" },
  },
  advance: {
    correctSpeechTextCounts: { ...initial, text: "300,000", color: "boost" },
    maxMessageLen: { ...initial, text: "140", color: "boost" },
    maxDictionaryCount: { ...initial, text: "300", color: "boost" },
    speed: { ...initial, icon: "mdi-check", color: "boost" },
    pitch: { ...initial, icon: "mdi-check", color: "boost" },
    readBotMessage: { ...initial, icon: "mdi-check", color: "boost" },
    botCount: { ...initial, text: "4", color: "boost" },
  },
};
const labels: Array<{ name: string; key: keyof (typeof data)["basic"] }> = [
  { name: "月の読み上げ文字数", key: "correctSpeechTextCounts" },
  { name: "最大メッセージ長", key: "maxMessageLen" },
  { name: "辞書スロット", key: "maxDictionaryCount" },
  { name: "スピード変更", key: "speed" },
  { name: "ピッチ変更", key: "pitch" },
  { name: "BOTの読み上げ", key: "readBotMessage" },
  { name: "BOT台数", key: "botCount" },
];
</script>

<style lang="scss">
$color-boost: var(--v-theme-boost);

.vc-plan {
  max-width: 1000px;
}

.p-plan__table-top th {
  border: none;
  background-color: transparent;
}

.p-plan__title {
  margin-bottom: 60px;
}

.p-plan__table {
  th {
    height: 100px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    vertical-align: bottom;
  }

  td,
  th {
    padding: 8px 16px !important;
    border-top-color: #999 !important;
    border-bottom-color: #999 !important;
    .is-mobile & {
      padding: 8px 2px !important;
    }
  }

  .p-plan__osusume {
    border-left: 2px solid rgb($color-boost) !important;
    border-right: 2px solid rgb($color-boost) !important;
    background-color: rgba($color-boost, 0.075) !important;
  }

  .p-plan__osusume--top {
    position: relative;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top: 2px solid rgb($color-boost) !important;
  }

  .p-plan__osusume--bottom {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 2px solid rgb($color-boost) !important;
  }
}
</style>
