import type { Pinia } from "pinia";
import type { RouteLocationNormalized, Router } from "vue-router";
import { useRuntimeConfig } from "@/composable/useRuntimeConfig";
import { useAsyncUpdaterStore } from "@/stores/useAsyncUpdaterStore";
import { useChannelStore } from "@/stores/useChannelStore";
import { useDictionaryStore } from "@/stores/useDictionaryStore";
import { useGuildStore } from "@/stores/useGuildStore";
import { useLoadingStore } from "@/stores/useLoadingStore";
import { useMemberStore } from "@/stores/useMemberStore";
import { useMenuStore } from "@/stores/useMenuStore";
import { useSnackbarStore } from "@/stores/useSnackbarStore";
import { useUserStore } from "@/stores/useUserStore";
import { useVChannelStore } from "@/stores/useVChannelStore";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useVMemberStore } from "@/stores/useVMemberStore";
import { useVModeratorStore } from "@/stores/useVModeratorStore";
import { useVUserStore } from "@/stores/useVUserStore";
import { usePaymentMethodStore } from "@/stores/usePaymentMethodStore";

export default (pinia: Pinia, to: RouteLocationNormalized, router: Router) => {
  useAsyncUpdaterStore(pinia);
  useChannelStore(pinia);
  useDictionaryStore(pinia);
  const guildStore = useGuildStore(pinia);
  const loadingStore = useLoadingStore(pinia);
  useMemberStore(pinia);
  const menuStore = useMenuStore(pinia);
  useSnackbarStore(pinia);
  const userStore = useUserStore(pinia);
  useVChannelStore(pinia);
  const vGuildStore = useVGuildStore(pinia);
  useVMemberStore(pinia);
  useVModeratorStore(pinia);
  const vUserStore = useVUserStore(pinia);
  usePaymentMethodStore(pinia);

  if (["/payment_methods/success_callback", "/payment_methods/cancel_callback"].includes(to.path)) {
    return;
  }

  (async () => {
    if (!userStore.user) {
      await userStore.fetchUser();
    }

    if (userStore.user) {
      await vUserStore.fetchVUser(userStore.user.id);
    } else {
      menuStore.$reset();
      guildStore.$reset();
      vGuildStore.$reset();
      const { siteUrl } = useRuntimeConfig();
      window.location.href = siteUrl;
      return;
    }

    await guildStore.fetchGuilds();

    const { guildId: routeGuildId } = to.params;
    if (!routeGuildId) {
      await menuStore.setMenu();
      guildStore.setGuild();
      vGuildStore.setVGuild();
      return;
    }

    const guildId = Array.isArray(routeGuildId) ? routeGuildId[0] : routeGuildId;
    const guild = guildStore.guilds.find((guild) => guild.id === guildId);
    if (!guild) {
      menuStore.unsetMenu();
      return;
    }

    guildStore.setGuild(guild);
    if (!guild.has_v_guild) {
      vGuildStore.setVGuild();
      menuStore.unsetMenu();
      return;
    }

    const vGuild = await vGuildStore.fetchVGuild(guild.id);
    await menuStore.setMenu(guild, vGuild);
    if (!menuStore.menus.find((menu) => menu.to === to.path)) {
      await router.push(menuStore.menus[0].to);
    } else {
      vGuildStore.setVGuild(vGuild);
      if (to.query.navigate_to) {
        await router.push(to.query.navigate_to.toString());
      }
    }
  })().then(() => {
    loadingStore.isSetupLoadingDone();
  });
};
