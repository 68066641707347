<template>
  <v-card class="c-guild-boost-card">
    <div class="d-flex mb-2 align-center">
      <VcGuildHeadline :guild="guild" />
      <v-spacer />
      <VcBoostDialog v-if="guild?.bot_joined" :guild-id="guild.id" @update="onUpdateBoosters">
        <template #default="{ props: boostDialogProps }">
          <v-btn v-bind="boostDialogProps" color="boost">
            {{ mobile ? "ブーストする" : "このサーバーをブーストする" }}
          </v-btn>
        </template>
      </VcBoostDialog>
    </div>

    <VcSpeechCountProgressBar v-if="guild?.bot_joined" v-bind="{ currentSpeechTextCount, maxSpeechTextCount }" />
    <div v-else-if="guild?.id" class="c-guild-boost-card__not-joined mb-2">
      <div class="text-danger-lighten">※ VOISCORDがサーバーに参加していません</div>
      <VcGuildInvite :guild-id="guild?.id" size="small" :with-container="false" />
    </div>
    <div v-else class="c-guild-boost-card__not-joined text-danger-lighten mb-2">
      ※ ユーザーおよびVOISCORDがサーバーに参加していません
    </div>

    <template v-for="boost in sortBoosts(boosts)" :key="boost.id">
      <v-divider color="black" />
      <div class="c-guild-boost-card__boost bg-black d-flex align-center">
        <div class="py-2">
          <div class="d-flex align-center flex-wrap">
            <div class="d-flex align-center">
              <v-icon class="mr-2" color="boost" size="small">mdi-lightning-bolt</v-icon>
              {{ formatDate(boost.guild_updated_at) }}からブースト
            </div>
            <VcCard class="ml-2" :payment-method="boost.payment_method" :show-default="false" />
          </div>
          <div v-if="boost.next_movable_duration_hours || boost.status === 'canceled'" class="mt-2 ml-8 text-body-2">
            <template v-if="boost.next_movable_duration_hours">
              {{ boost.next_movable_duration_hours }}時間後に再転送可能
            </template>
            <span v-if="boost.status === 'canceled'" class="ml-2 text-danger">
              ({{ formatDate(boost.next_payment_at) }}まで有効)
            </span>
          </div>
        </div>
        <v-spacer />
        <v-menu>
          <template #activator="{ props: activatorProps }">
            <v-btn
              v-if="isMovable(boost) || isCancelable(boost)"
              v-bind="activatorProps"
              icon="mdi-dots-vertical"
              size="small"
              vairant="text"
              class="bg-transparent"
            />
          </template>
          <v-list>
            <v-list-item v-if="isMovable(boost)" @click="openMoveBoostDialog(boost)">
              <v-list-item-title>ブーストを転送</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="isCancelable(boost)" @click="openCancelBoostDialog(boost)">
              <v-list-item-title class="text-danger-lighten">サブスクリプションを解約</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
  </v-card>
</template>

<script setup lang="ts">
import type { Guild, Boost, User } from "@/types";
import { useDisplay } from "vuetify";
import { useBoostStore } from "@/stores/useBoostStore";
import { formatDate } from "@/utilities/voiscord";

const { mobile } = useDisplay();

const props = defineProps<{
  guild?: Guild;
  boosts: Boost[];
  currentSpeechTextCount: number;
  maxSpeechTextCount: number;
}>();

const emit = defineEmits<{
  (e: "update", boosters: User[]): void;
}>();

const boostStore = useBoostStore();

const isMovable = (boost: Boost) => boost.next_movable_duration_hours === 0;
const isCancelable = (boost: Boost) => boost.status !== "canceled";

const onUpdateBoosters = (boosters: User[]) => {
  emit("update", boosters);
};

const openMoveBoostDialog = (boost: Boost) => {
  boostStore.setMoveTarget(boost, props.guild);
};

const openCancelBoostDialog = (boost: Boost) => {
  boostStore.setCancelTarget(boost);
};

const sortBoosts = (boosts: Boost[]): Boost[] => {
  return boosts.sort((a, b) => {
    return new Date(a.guild_updated_at).getTime() - new Date(b.guild_updated_at).getTime();
  });
};
</script>

<style lang="scss">
.c-guild-boost-card {
  width: 100%;
  padding: 32px 16px 0 16px;
}

.c-guild-boost-card__boost {
  padding: 0 8px;
  margin: 0 -16px;
  // height: 40px;
  min-height: 40px;
}

.c-guild-boost-card__not-joined {
  margin-left: 80px;
}
</style>
