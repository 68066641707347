<template>
  <div class="mt-4 pa-2">
    <v-card class="mb-4" color="boost" @click="router.push('/boost')">
      <v-card-title>
        ブースト管理
        <v-icon icon="mdi-arrow-right-bold-circle" size="large" />
      </v-card-title>
      <div class="p-dashboard__guild-groups ma-3 d-flex">
        <div v-if="userBoostedGuilds.length === 0">サーバーをブーストして、特典をアンロックしよう！</div>
        <template v-else>
          <span v-for="info in userBoostedGuilds" :key="info.guild.id" class="relative">
            <GuildIcon :guild="info.guild" :size="48" />
            <v-chip
              variant="elevated"
              class="p-dashboard__boosted-guild-boost text-boost"
              density="compact"
              size="small"
              color="white"
            >
              <v-icon icon="mdi-lightning-bolt" /> x {{ info.boosts.length }}
            </v-chip>
          </span>
        </template>
      </div>
    </v-card>
    <v-expansion-panels multiple :model-value="[0, 1, 2]">
      <v-expansion-panel v-for="(userGuilds, index) in guildsByStatus" :key="index">
        <v-expansion-panel-title>
          <div class="text-h6">{{ guildGroupNames[index] }}</div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="mx-n3">
          <div class="p-dashboard__guild-groups d-flex flex-wrap">
            <GuildIcon v-for="guild in userGuilds" :key="guild.id" :guild="guild" :size="80" @click="onClick(guild)" />
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script setup lang="ts">
import type { BoostInfo, Guild } from "@/types";
import { storeToRefs } from "pinia";
import { computed, nextTick, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAPI } from "@/composable/useAPI";
import { useGuildStore } from "@/stores/useGuildStore";
import { useLoadingStore } from "@/stores/useLoadingStore";

const route = useRoute();
const router = useRouter();

const loadingStore = useLoadingStore();
const guildStore = useGuildStore();

const { guilds } = storeToRefs(guildStore);

const userBoostedGuilds = ref<BoostInfo[]>([]);

const guildsByStatus = computed(() => {
  if (!guilds.value) {
    return [];
  }

  return [guildStore.managedJoinedGuilds, guildStore.notManagedJoinedGuilds, guildStore.managedNotJoinedGuilds];
});

const guildGroupNames = ["BOT導入済み（管理権限あり）", "BOT導入済み（管理権限なし）", "BOT未導入"];

const onClick = (guild: Guild) => {
  loadingStore.setIsGuildChangeLoading(true);
  nextTick(() => {
    const query = route.query.navigate_to ? `?navigate_to=${route.query.navigate_to}` : "";
    router.push(`/server/${guild.id}/general${query}`);
  });
};

const init = async () => {
  const res = await useAPI().get("/api/boosts");
  userBoostedGuilds.value = res.data.user_boosted_guilds;
};

onMounted(() => init());
</script>

<style lang="scss">
.p-dashboard__guild-groups {
  gap: 8px;
}

.p-dashboard__boosted-guild-boost {
  position: absolute;
  top: -4px;
  right: -12px;
  font-size: 10px !important;
  padding: 2px 8px 2px 2px !important;
  height: 12px !important;
}
</style>
