import type { Guild, VGuild } from "@/types";
import { defineStore } from "pinia";

interface Menu {
  title: string;
  to: string;
  icon: string;
  name: string;
  color?: string;
}

const memberMenu = (guildId: string) => ({
  title: "メンバー設定",
  icon: "mdi-account-multiple",
  to: `/server/${guildId}/member`,
  name: "member",
});
const guildMenus = (guildId: string, showBots?: boolean) => [
  {
    title: "一般設定",
    icon: "mdi-cog",
    to: `/server/${guildId}/general`,
    name: "general",
  },
  memberMenu(guildId),
  {
    title: "チャンネル設定",
    icon: "mdi-format-list-bulleted",
    to: `/server/${guildId}/channel`,
    name: "channel",
  },
  {
    title: "辞書設定",
    icon: "mdi-furigana-horizontal",
    to: `/server/${guildId}/dictionary`,
    name: "dictionary",
  },
  {
    title: "モデレーター設定",
    icon: "mdi-wrench",
    to: `/server/${guildId}/moderator`,
    name: "moderator",
  },
  ...(showBots
    ? [
        {
          title: "追加BOTの招待",
          icon: "mdi-account-multiple-plus",
          to: `/server/${guildId}/bots`,
          name: "bots",
          color: "boost",
        },
      ]
    : []),
  {
    title: "ブースト",
    icon: "mdi-lightning-bolt",
    to: `/server/${guildId}/boost`,
    name: "boost",
    color: "boost",
  },
];
const noGuildMenu = [
  {
    title: "サーバーを選択",
    icon: "mdi-home",
    to: "/dashboard",
    name: "dashboard",
  },
  {
    title: "決済履歴",
    icon: "mdi-credit-card-clock-outline",
    to: "/payments",
    name: "payments",
  },
  {
    title: "ブーストを管理",
    icon: "mdi-lightning-bolt",
    to: "/boost",
    name: "boostManagement",
    color: "boost",
  },
];

export const useMenuStore = defineStore("menus", {
  state: () => {
    return {
      menus: [] as Menu[],
    };
  },

  actions: {
    async setMenu(guild?: Guild, vGuild?: VGuild) {
      if (!guild) {
        this.menus = noGuildMenu;
      } else if (guild.status === "not_managed_joined") {
        if (vGuild?.is_moderator) {
          this.menus = guildMenus(guild.id);
        } else {
          this.menus = [
            memberMenu(guild.id),
            {
              title: "ブースト",
              icon: "mdi-lightning-bolt",
              to: `/server/${guild.id}/boost`,
              name: "boost",
              color: "boost",
            },
          ];
        }
      } else {
        const boostCount = vGuild?.boosters?.reduce((sum, booster) => sum + (booster.boostCount || 0), 0) || 0;
        const showBots = guild.status === "managed_joined" && boostCount >= 3;
        this.menus = guildMenus(guild.id, showBots);
      }
    },
    unsetMenu() {
      this.menus = [];
    },
  },
});
