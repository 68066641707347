<template>
  <VcGuildInvite v-if="guild?.status === 'managed_not_joined'" />
  <div
    v-if="vGuild && vGuild.id !== '0'"
    class="p-moderator h-100 w-100 d-flex flex-column"
    :class="{ 'is-mobile': mobile }"
  >
    <div class="p-moderator__header v-container pa-0">
      <div class="p-moderator__search-container bg-background" :class="{ 'is-managed': !guildStore.isGuildNotManaged }">
        <div class="d-flex flex-column justify-space-between h-100">
          <div class="mt-4">
            <v-alert
              class="pa-3"
              type="info"
              title="下記ロールを所有するユーザーがVOISCORDを設定できます。"
              variant="tonal"
            >
              ・サーバー管理権限を持つロール<br />
              ・この画面でモデレーターに設定したロール
            </v-alert>
          </div>
          <div v-if="guildStore.isGuildNotManaged">
            <v-alert
              class="pa-3"
              type="warning"
              variant="tonal"
              title="サーバー管理権限を持たないためモデレーター設定を変更できません。"
            />
          </div>
          <div class="mb-4">
            <v-text-field
              v-model="searchText"
              class="p-moderator__search"
              prepend-inner-icon="mdi-magnify"
              placeholder="ロールを検索"
              variant="solo"
              hide-details
            />
          </div>
        </div>
      </div>
      <div class="d-flex bg-surface-lighten-1">
        <div class="p-moderator__cell is-check"></div>
        <div class="p-moderator__cell">ロール名</div>
      </div>
    </div>
    <div class="p-moderator__body">
      <div v-for="role in searchedRoles" :key="role.id" class="d-flex p-moderator__row">
        <div class="p-moderator__cell is-check">
          <LockedIcon v-if="roleHasServerManage(role)" icon="mdi-wrench" />
          <v-checkbox
            v-else
            :model-value="isChecked(role)"
            hide-details
            density="compact"
            color="success"
            :disabled="guildStore.isGuildNotManaged"
            @update:model-value="toggleSelect(role)"
          />
        </div>
        <div class="p-moderator__cell is-word" :style="roleStyle(role)">
          {{ role.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Role } from "@/types";
import { ref, computed, watch } from "vue";
import { useDisplay } from "vuetify";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useSortBy } from "@/composable/useLodash";
import { useVModeratorStore } from "@/stores/useVModeratorStore";
import { useGuildStore } from "@/stores/useGuildStore";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useLoadingStore } from "@/stores/useLoadingStore";
import { DiscordPermission } from "@/const";

const { mobile } = useDisplay();
const route = useRoute();

const searchText = ref("");

const vModeratorStore = useVModeratorStore();
const guildStore = useGuildStore();
const loadingStore = useLoadingStore();
const vGuildStore = useVGuildStore();

const { roles, vModerators } = storeToRefs(vModeratorStore);
const { guild } = storeToRefs(guildStore);
const { vGuild } = storeToRefs(vGuildStore);

const searchedRoles = computed(() => {
  const text = searchText.value.trim();
  const filtered = roles.value.filter((role) => {
    return role.name.includes(text) || role.id === text;
  });
  return useSortBy(filtered, (role) => {
    return -(role.position || 0);
  });
});

const isChecked = (role: Role) => {
  return !!vModerators.value.find((vModerator) => {
    return vModerator.role_id === role.id;
  });
};

const toggleSelect = (role: Role) => {
  if (isChecked(role)) {
    vModeratorStore.deleteVModerator(role);
  } else {
    vModeratorStore.addVModerator(role);
  }
};

const roleHasServerManage = (role: Role) => {
  return !!(role.permissions & DiscordPermission.ADMINISTRATOR || role.permissions & DiscordPermission.MANAGE_GUILD);
};
const rgbColorToColorCode = (rgbColor: number | undefined) => {
  if (rgbColor) {
    return `#${rgbColor.toString(16)}`;
  }
};
const roleStyle = (role: Role) => {
  return {
    color: rgbColorToColorCode(role.color),
  };
};

const initialize = async () => {
  if (!route.path.match(/\/server\/\d+\/moderator/)) return;

  if (vGuild.value.guild_id !== "0") {
    loadingStore.setIsGuildChangeLoading(true);
    await Promise.all([vModeratorStore.fetchRoles(), vModeratorStore.fetchVModerators()]);
  }
  loadingStore.setIsGuildChangeLoading(false);
};
watch(vGuild, initialize);
watch(guild, () => {
  if (vGuild.value.guild_id === "0") loadingStore.setIsGuildChangeLoading(false);
});
</script>

<style lang="scss" scoped>
.p-moderator {
  position: relative;
}

.p-moderator__header {
  top: 64px;
  z-index: 1;
  position: sticky;
  width: 100%;
}

.p-moderator__search-container {
  height: 280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;

  &.is-managed {
    height: 200px;
  }

  .is-mobile & {
    height: 300px;
  }

  .is-mobile &.is-managed {
    height: 226px;
  }
}

.p-moderator__search {
  width: 50%;
  max-width: 50%;

  .is-mobile & {
    width: 100%;
    max-width: 100%;
  }
}

.p-moderator__body {
  overflow-x: hidden;
  overflow-y: scroll;
  flex: 1;
  padding-bottom: calc(100dvh - 328px);
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .is-mobile & {
    padding-bottom: calc(100dvh - 288px);
  }
}

.p-moderator__cell {
  height: 48px;
  line-height: 48px;
  padding: 0 8px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;

  &.is-check {
    min-width: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.is-word {
    flex: 1;
  }
}
</style>
