<template>
  <div class="d-flex align-center justify-start mb-6">
    <v-label class="c-speech-count-progress-bar__label text-subtitle-2" text="読み上げ枠" />
    <v-progress-linear
      :model-value="(currentSpeechTextCount / maxSpeechTextCount) * 100"
      :color="progressColor"
      class="flex-grow-1"
      height="10"
      striped
      :style="{ width: `${barWidth}px` }"
    />
    <v-tooltip :close-delay="0" :open-delay="0" location="top">
      <template #default>
        {{ currentSpeechTextCount.toLocaleString() }} / {{ maxSpeechTextCount.toLocaleString() }}
      </template>
      <template #activator="{ props: activatorProps }">
        <v-btn
          v-bind="activatorProps"
          class="c-speech-count-progress-bar__percent ml-2 font-weight-bold text-white"
          variant="text"
          density="compact"
        >
          {{ actualRate }}%
        </v-btn>
      </template>
    </v-tooltip>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    currentSpeechTextCount: number;
    maxSpeechTextCount: number;
    width?: number;
  }>(),
  {
    width: 530,
  },
);

const actualRate = computed(() => {
  return Math.floor((props.currentSpeechTextCount / props.maxSpeechTextCount) * 100);
});

const rate = computed(() => {
  return Math.min(actualRate.value, 100);
});

const progressColor = computed(() => {
  return rate.value >= 80
    ? "red-darken-4"
    : rate.value >= 60
      ? "deep-orange"
      : rate.value >= 40
        ? "lime"
        : rate.value >= 20
          ? "light-green-darken-4"
          : "light-blue";
});

const barWidth = computed(() => Math.max(props.width - 130, 100));
</script>

<style lang="scss">
.c-speech-count-progress-bar__label {
  width: 70px;
  min-width: 70px;
  margin-right: 12px;
}

.c-speech-count-progress-bar__percent {
  width: 40px;
  text-align: right;
}
</style>
