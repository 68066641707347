<template>
  <div class="pa-1 font-weight-bold">
    <v-card class="d-flex flex-column pa-4">
      <div class="d-flex align-center justify-center text-h6" :class="[`text-${color}`, { 'text-subtitle-1': mobile }]">
        <v-icon v-if="icon" :icon="icon" />
        {{ props.title }}
      </div>
      <v-divider class="wave-box" :class="`text-${color}`" thickness="3" opacity="100" />
      <div :class="`text-${color}`">
        <div class="d-flex justify-center">
          <div>
            {{ numberFormatter(props.num) }}
          </div>
          <div style="margin-left: 6px">{{ props.unit }}</div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { useDisplay } from "vuetify";

const { mobile } = useDisplay();

const props = withDefaults(
  defineProps<{
    title: string;
    num: number;
    unit: string;
    color: string;
    icon: string;
  }>(),
  {},
);

const numberFormatter = (n: number): string => {
  return n.toLocaleString();
};
</script>
