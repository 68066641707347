import type { AxiosError } from "axios";
import type { Dictionary } from "@/types";
import { defineStore } from "pinia";
import { useAPI } from "@/composable/useAPI";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useSnackbarStore } from "@/stores/useSnackbarStore";

const getGuildId = () => useVGuildStore().vGuild.guild_id;

const handleError = (_axiosError: AxiosError) => {
  useSnackbarStore().addDefaultGetError();
};

export const useDictionaryStore = defineStore("dictionaries", {
  state: () => {
    return {
      dictionaries: [] as Dictionary[],
    };
  },

  actions: {
    async fetchFromVGuild() {
      const guildId = getGuildId();
      if (guildId === "0") return;

      const response = await useAPI().get(`/api/v_guilds/${guildId}/dictionaries`).catch(handleError);
      if (!response) return;

      this.dictionaries = response.data;
    },
    async create(dictionary: Pick<Dictionary, "before" | "after">) {
      const guildId = getGuildId();
      if (guildId === "0") return;

      useAPI()
        .post(`/api/v_guilds/${guildId}/dictionaries`, dictionary)
        .then(({ data }) => {
          this.dictionaries.push(data);
          useSnackbarStore().addTopRight({ color: "success", text: "更新しました" });
        })
        .catch((error) => {
          useSnackbarStore().addError(error);
        });
    },
    async update(dictionary: Partial<Dictionary>) {
      const guildId = getGuildId();
      if (guildId === "0") return;

      const response = await useAPI()
        .patch(`/api/v_guilds/${guildId}/dictionaries/${dictionary.id}`, {
          before: dictionary.before,
          after: dictionary.after,
        })
        .catch(handleError);
      if (!response) return;

      const index = this.dictionaries.findIndex(({ id }) => id === dictionary.id);
      if (index < 0) return;

      this.dictionaries.splice(index, 1, response.data);
      useSnackbarStore().addTopRight({ color: "success", text: "更新しました" });
    },
    async delete(ids: (string | bigint)[]) {
      const guildId = getGuildId();
      if (guildId === "0") return;

      const response = await useAPI()
        .delete(`/api/v_guilds/${guildId}/dictionaries`, { data: { ids } })
        .catch(handleError);
      if (!response) return false;

      this.dictionaries = this.dictionaries.filter(({ id }) => !ids.includes(id));
      useSnackbarStore().addTopRight({ color: "success", text: "削除しました" });
      return true;
    },
  },
});
