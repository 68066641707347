<template>
  <div>
    <VcDiscordInviteButton
      v-for="bot in bots"
      :key="bot.text"
      :href="bot.url"
      :text="bot.text"
      class="p-bots__invite-button mx-auto my-12"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useRuntimeConfig } from "@/composable/useRuntimeConfig";
import { useGuildStore } from "@/stores/useGuildStore";

const { discordAdditionalOauthClientIds, discordBotPermissions } = useRuntimeConfig();

const guildStore = useGuildStore();
const { guild } = storeToRefs(guildStore);

const botNames = ["α", "β", "γ"];

const buildInviteUrl = (clientId: string, guildId?: string) => {
  const params = new URLSearchParams({
    client_id: clientId,
    permissions: discordBotPermissions,
    scope: "bot",
    guild_id: guildId || "",
  });
  return `https://discord.com/oauth2/authorize?${params}`;
};

const bots = computed(() => {
  return discordAdditionalOauthClientIds.map((clientId, index) => ({
    url: buildInviteUrl(clientId, guild.value?.id),
    text: `VOISCORD(${botNames[index]})をサーバーに招待`,
  }));
});
</script>

<style lang="scss">
.p-bots__invite-button {
  text-transform: none !important;
}
</style>
