<template>
  <VcGuildInvite v-if="guild?.status === 'managed_not_joined'" />
  <div v-if="vGuild && vGuild.id !== '0'" class="p-channel pa-2">
    <div class="h-100 d-flex">
      <VcGuildVoiceChannels v-if="!mobile || !currentChannel" class="flex-grow-1 flex-basis-0 d-flex flex-column" />
      <v-divider v-if="!mobile" class="mx-2" color="black" thickness="2" vertical />
      <VcGuildTextChannels v-if="!mobile && currentChannel" class="flex-grow-1 flex-basis-0 d-flex flex-column" />
      <v-dialog
        v-if="mobile && !isGuildChangeLoading && currentChannel"
        :model-value="true"
        class="channel_dialog"
        fullscreen
        scrollable
      >
        <v-card>
          <v-toolbar theme="dark">
            <v-btn icon @click="currentChannel = undefined">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              <VcGuildChannelItem transparent :channel="{ ...currentChannel, parent: undefined, children: [] }" />
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-1">
            <VcGuildTextChannels />
          </v-card-text>
        </v-card>
      </v-dialog>
      <div v-if="!mobile && !currentChannel" class="flex-grow-1 flex-basis-0 d-flex flex-column" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Channel } from "@/types";
import { computed, watch, provide } from "vue";
import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";
import { useRoute } from "vue-router";
import { useGuildStore } from "@/stores/useGuildStore";
import { useVGuildStore } from "@/stores/useVGuildStore";
import { useChannelStore } from "@/stores/useChannelStore";
import { useVChannelStore } from "@/stores/useVChannelStore";
import { useLoadingStore } from "@/stores/useLoadingStore";
import { ChannelTypes } from "@/const";

const { mobile } = useDisplay();
const route = useRoute();

const guildStore = useGuildStore();
const vGuildStore = useVGuildStore();
const channelStore = useChannelStore();
const vChannelStore = useVChannelStore();
const loadingStore = useLoadingStore();

const { guild } = storeToRefs(guildStore);
const { vGuild } = storeToRefs(vGuildStore);
const { currentChannel } = storeToRefs(channelStore);
const { vChannels } = storeToRefs(vChannelStore);
const { isGuildChangeLoading } = storeToRefs(loadingStore);

const guildChannel = computed(
  () =>
    ({
      id: vGuild.value.guild_id,
      guild_id: vGuild.value.guild_id,
      type: ChannelTypes.GUILD_VOICE,
      name: "サーバーの共通設定",
      children: [],
      vChannel: vChannels.value.find((vChannel) => vChannel.channel_id === vGuild.value.guild_id) || {
        channel_id: vGuild.value.guild_id,
        auto_join: false,
        channel_ids: [],
        ignore_channel_ids: [],
      },
    }) as Channel,
);

watch(vGuild, async () => {
  if (!route.path.match(/\/server\/\d+\/channel/)) return;

  if (vGuild.value.guild_id !== "0") {
    loadingStore.setIsGuildChangeLoading(true);
    await Promise.all([channelStore.fetchChannels(), vChannelStore.fetchVChannels()]).then(() => {
      channelStore.updateCurrentChannel(mobile.value ? undefined : guildChannel.value);
    });
    loadingStore.setIsGuildChangeLoading(false);
  }
});
watch(guild, () => {
  if (vGuild.value.guild_id === "0") loadingStore.setIsGuildChangeLoading(false);
});
provide("guildChannel", guildChannel);
</script>

<style lang="scss">
@use "@/stylesheets/mixin.scss";

.p-channel {
  height: calc(100dvh - 64px);
}

.p-channel__side-header {
  margin-top: 32px;
  min-height: 80px;
}

.p-channel__search {
  width: 100%;
  margin-bottom: 8px;
}

.p-channel__list {
  @include mixin.no-scrollbar;

  overflow-y: auto;
  position: relative;
}

.p-channel__search-target {
  width: 300px;
  max-width: 300px;
}

.p-channel .v-checkbox .v-selection-control {
  min-height: auto;
}
</style>
