<template>
  <div class="d-flex align-center">
    <div class="vc-card__icon-container">
      <img class="vc-card__icon" alt="credit-card" :src="`/payments/${paymentMethodIcon(paymentMethod)}`" />
    </div>
    <div class="vc-card__number">
      {{ formatCardNumber(paymentMethod, mobile) }}
      <span v-if="showDefault && paymentMethod.default_flag" class="ml-2" style="color: #ffee58ff">
        {{ mobile ? "(デフォルト)" : "(デフォルトの決済方法)" }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PaymentMethod } from "types";
import { useDisplay } from "vuetify";
import { formatCardNumber, paymentMethodIcon } from "@/utilities/voiscord";

const { mobile } = useDisplay();

defineProps<{
  paymentMethod: PaymentMethod;
  showDefault: boolean;
}>();
</script>

<style lang="scss">
.vc-card__icon-container {
  display: flex;
  justify-content: center;
  margin-right: 6px;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  pointer-events: none;
}

.vc-card__icon {
  height: 25px;
  border-radius: 4px;
  background-color: transparent;

  .is-mobile & {
    height: 20px;
  }
}

.vc-card__number {
  user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  pointer-events: none;
}
</style>
