<template>
  <div v-if="guildId || (guild && !guild.bot_joined)" :class="containerClass">
    <div class="d-flex align-center" :class="{ 'flex-column': withContainer }">
      <VcDiscordInviteButton :href="inviteUrl" :size="size" />
      <div class="d-inline-block mb-2 nowrap mx-2" :class="{ 'mt-10': withContainer, 'mt-4': !withContainer }">
        {{ mobile ? "または" : "または、すでにBOTを招待済みの場合：" }}
      </div>
      <v-btn
        :class="{ 'vc-guild-invite__claim-bot-guild-button': withContainer }"
        color="discord-primary"
        :size="withContainer ? 'x-large' : undefined"
        @click="claimBotGuild"
      >
        {{ mobile ? "再読込" : "サーバーを読み込む" }}
      </v-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";
import { useRuntimeConfig } from "@/composable/useRuntimeConfig";
import { useAPI } from "@/composable/useAPI";
import { useGuildStore } from "@/stores/useGuildStore";
import { useLoadingStore } from "@/stores/useLoadingStore";

const { mobile } = useDisplay();

const props = withDefaults(
  defineProps<{
    guildId?: string;
    size?: string;
    withContainer?: boolean;
  }>(),
  {
    guildId: undefined,
    size: undefined,
    withContainer: true,
  },
);

const { discordOauthClientId, discordBotPermissions } = useRuntimeConfig();

const guildStore = useGuildStore();
const loadingStore = useLoadingStore();

const { guild } = storeToRefs(guildStore);

const guildId = computed(() => props.guildId || guild.value?.id);

const inviteUrl = computed(() => {
  const params = new URLSearchParams({
    client_id: discordOauthClientId,
    permissions: discordBotPermissions,
    scope: "bot",
    guild_id: guildId.value || "",
  });
  return `https://discord.com/oauth2/authorize?${params}`;
});

const containerClass = computed(() => {
  return props.withContainer ? "d-flex h-100 w-100 align-center justify-center pt-6" : "d-inline-block";
});

const claimBotGuild = () => {
  if (!guildId.value) return;

  loadingStore.setIsGuildChangeLoading(true);
  useAPI()
    .post(`/api/guilds/${guildId.value}/claim_bot_guild`)
    .then(() => {
      window.location.reload();
    });
};
</script>

<style lang="scss">
.vc-guild-invite__claim-bot-guild-button {
  width: 345px;
  height: 58px;
  min-height: 58px;
}
</style>
