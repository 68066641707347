import type { Boost, Guild } from "@/types";
import { defineStore } from "pinia";

export const useBoostStore = defineStore("boosts", {
  state: () => {
    return {
      moveTargetBoost: undefined as Boost | undefined,
      guildFrom: undefined as Guild | undefined,
      cancelTargetBoost: undefined as Boost | undefined,
    };
  },

  actions: {
    setMoveTarget(boost?: Boost, guildFrom?: Guild) {
      this.moveTargetBoost = boost;
      this.guildFrom = guildFrom;
    },
    setCancelTarget(boost?: Boost) {
      this.cancelTargetBoost = boost;
    },
  },
});
