<template>
  <div class="d-flex align-center">
    <GuildIcon :guild="guild" :size="iconSize" :hoverable="false" :active="false" />
    <div>
      <div class="font-weight-bold ml-4 mb-1" :style="{ fontSize: guildNameFontSize }">
        {{ displayGuildName }}
      </div>
      <div class="d-flex ml-3 text-grey" :style="{ fontSize: boostTextFontSize }">
        <v-icon color="boost">mdi-lightning-bolt</v-icon>
        {{ displayBoost }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Guild } from "@/types";
import { computed } from "vue";
import GuildIcon from "./GuildIcon.vue";

const props = defineProps<{
  guild?: Guild;
  boostCount?: number;
  size?: "small";
}>();

const iconSize = computed(() => (props.size === "small" ? 48 : 64));
const guildNameFontSize = computed(() => {
  return props.size === "small" ? "14px" : "20px";
});
const boostTextFontSize = computed(() => {
  return props.size === "small" ? "11px" : "14px";
});

const boostCount = computed(() => props.guild?.boostCount || props.boostCount || 0);
const displayGuildName = computed(() => {
  return props.guild?.name || "サーバーを取得できませんでした";
});
const displayBoost = computed(() => {
  return boostCount.value > 0 ? `${boostCount.value}ブースト` : "ブーストなし";
});
</script>

<style lang="scss">
.c-guild-headline__avatar {
  border: 1px solid #000;
}
</style>
