import type { ThemeDefinition } from "vuetify";
import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";

const voiscord: ThemeDefinition = {
  dark: false,
  colors: {
    background: "#37393e",
    "background-lighten-1": "#757575",
    surface: "#202225",
    "surface-lighten-1": "#2c2f33",
    primary: "#50a261",
    "primary-darken-1": "#3700B3",
    secondary: "#03DAC6",
    "secondary-darken-1": "#018786",
    user: "#FB8C00",
    error: "#B00020",
    danger: "#B00020",
    boost: "#E53935",
    "boost-lighten": "#E57373",
    "danger-lighten": "#EF5350",
    info: "#2196F3",
    success: "#4CAF50",
    "success-lighten": "#A5D6A7",
    warning: "#FB8C00",
    cancel: "#F4E8F1",
    inactive: "#81848d",
    "inactive-darken": "#222222",
    nightly: "#1b005a",
    "nightly-secondary": "#884684",
    "discord-primary": "#5865F2",
    "nomal-button-color": "#404040",
    // #F4E8F1
    // #442342
    // #6200EE
    // #884684
  },
};

const Vuetify = createVuetify({
  theme: {
    defaultTheme: "voiscord",
    themes: {
      voiscord,
    },
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
});

export default Vuetify;
